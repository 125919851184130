import { createProjectMetaApiClient } from "~/modules/ServerHelpers/apiHelpers";

export default defineNuxtRouteMiddleware(async (to, from) => {
  try {
    const projectId = Number(to.params.projectId);
    const apiClient = await createProjectMetaApiClient();
    const project = await apiClient.projects(projectId);
    to.params.projectName = project.name;
    to.params.websites = project.websites;
  } catch (e) {
    // If it fails, we don't care, we just don't have the project name
    to.params.projectName = new Array<string>();
    to.params.websites = new Array<string>();
  }
});
